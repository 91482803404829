export const conteudo = [
  {
    path: 'direito-regulatorio',
    lista: [
      "Assessoria em Licitações e contratos públicos. Representação de clientes em todas as etapas de processo de contratação pública, desde a licitação até o fim do contrato, incluindo demandas de reequilíbrio econômico-financeiro, prorrogação contratual e renovação de contratos administrativos, concessões, dentre outros",
      "Assessoria jurídica no relacionamento com empresas estatais, no que condiz ao regime jurídico do estatuto das empresas estatais, estruturação de parcerias estratégicas e operações societárias e de alienação de ativos de estatais",
      "Adequação regulatória para novas economias sustentáveis perante agências reguladoras",
      "Assessoria jurídica na obtenção e/ou manutenção de permissões, licenças e atos administrativos perante o poder público",
    ]
  },
  {
    path: 'direito-terceiro',
    lista: [
      "Assessoria na constituição de associações, fundações privadas e Coorperativas sociais, reorganização societária e estruturação de fundos patrimoniais (endowment) e outros mecanismos que visem à sustentabilidade das entidades no longo prazo.",
      "Assessoria jurídica para desenvolvimento de projetos de investimento social privado, na estruturação de negócios sociais e no desenvolvimento de fundos de impact investing",
      "Assessoria no gerenciamento das atividades realizadas pelas OSCs, bem como na implementação e estruturação de mecanismos de governança corporativa.",
      "Apoio na aplicação da lei anticorrupção e em auditoria legal preventiva.",
      "Consultoria sobre o regime tributário aplicável à entidade (imunidade ou isenção) e orientação sobre o melhor aproveitamento dos benefícios fiscais para as organizações.",
      "Estruturação financeira e planejamento a respeito dos benefícios fiscais, como Leis de Incentivo à Cultura nas diferentes esferas governamentais.",
      "Apoio na elaboração dos instrumentos contratuais necessários para a viabilização dos incentivos fiscais perante o poder público e instituições privadas. Assessoria no desenvolvimento de atividades-meio que gerem receitas visando a garantir a sustentabilidade das entidades.",
      "Orientação jurídica na obtenção de qualificações conferidas pelo poder público, como Certificação de Entidades Beneficentes de Assistência Social (Cebas), Organização da Sociedade Civil de Interesse Público (Oscip) e Organização Social (OS)",
      "Apoio na elaboração e negociação de contratos e parcerias com o poder público (contratos de gestão, termos de parceria, termos de fomento e colaboração), bem como gestão de recursos públicos e assessoramento de procedimentos de prestação de contas perante os órgãos de controle.",
    ]
  },
  {
    path: 'direito-ambiental',
    lista: [
      "Licenciamento",
      "Acompanhamento especializado para emissão desde a licença prévia até as licenças de operação para atividades e empreendimentos, com o devido acompanhamento legal durante todo o processo.",
      "Estudos técnicos ambientais como: Estudo de Impacto Ambiental (EIA), Relatório de Impacto Ambiental (RIMA), Relatório de Controle Ambiental (RCA), Plano de Controle Ambiental (PCA) e demais estudos que se façam necessários.",
      "Representação junto aos órgãos públicos na implementação da Política Nacional de Resíduos Sólidos (Lei Federal n. 12.305/2010).",
      "Gestão Ambiental",
      "Mapeamento de partes interessadas (stakeholders).",
      "Implementação de Sistemas de Gestão Ambiental (SGA) e assessoria para a certificação por ISO 14.001/2015.",
      "Levantamento e gestão de requisitos legais.",
      "Assessoria estratégica para adequação à Política Nacional de Resíduos Sólidos (Lei Federal n. 12.305/2010) para o desenvolvimento de estratégias de gestão de resíduos, economia circular e de sistemas inovadores de logística reversa.",
      "Compliance Ambiental",
      "Assessoria jurídica para elaboração e revisão de políticas sociais, ambientais e climáticas e demais documentos aplicáveis de instituições financeiras e realização de due diligences.",
      "Consultoria sobre legislação ambiental e climática vigente, bem como sobre tendências regulatórias nacionais e internacionais, e assessoria jurídica estratégica para compliance com a legislação aplicável perante autoridades públicas e instituições financeiras.",
      "Contencioso",
      "Representação em processos judiciais e administrativos, investigativos e sancionatórios, assessoria na definição de estratégias e resultados diferenciados para solução de conflitos.",
    ]
  },
  {
    path: 'direito-empresarial',
    lista: [
      "Constituição de todos os tipos societários até a estruturação e o acompanhamento de sua governança corporativa.",
      "Estruturação de negócios de impacto no Brasil e no exterior, representação de grupos internacionais com atuação no Brasil, dissolução de sociedades e contencioso societário especializado.",
      "Elaboração e negociação de contratos empresariais, incluindo contratos de compra e venda de participação societária, acordos de investimento, acordos de acionistas ou de quotistas, contratos de opção de compra e venda (put/call options), memorando de entendimentos, term sheet, acordos de confidencialidade, entre outros.",
    ]
  },
  {
    path: 'contratos-negocios',
    lista: [
      "Estruturação, revisão e negociação de acordos comerciais das mais variadas naturezas, inclusive envolvendo o desenvolvimento de projetos e de impacto e/ou novas economias sustentáveis;",
      "Assessoria na gestão das relações contratuais, no treinamento de equipes internas, na estruturação de políticas comerciais, entre outros aspectos relevantes para as empresas, seja como contratantes ou contratadas do fornecimento de produtos e serviços;",
      "Assistência na gestão de riscos e pleitos relacionados a contratos complexos envolvendo a estruturação de negócios de impacto;",
    ]
  },
  {
    path: 'propriedade-intelectual',
    lista: [
      "Elaboração e acompanhamento de pedidos de registro de marcas perante o INPI, bem como desenvolvimento e acompanhamento dos demais recursos administrativos como oposição, PAN, etc;",
      "Proteção, registro e manutenção de nomes de domínio, software, bem como a gestão dos direitos de propriedade sobre sites perante o Comitê Gestor da Internet no Brasil (CGI.br);",
      "Elaboração de contratos/autorizações de cessão e licenciamento de marcas; imagem e voz;",
      "Construção de estratégias para questões e proteção dos Segredos Comerciais e Concorrência desleal;",
      "Assessoria para registro de obra intelectual (obra literária, artística, científica ou qualquer outra espécie de criação intelectual);",
    ]
  },
  {
    path: 'impact-investing',
    lista: [
      "Assessoria envolvendo investimentos de private equity e venture capital para negócios de impacto, auxiliando os clientes (incluindo fundos de investimento de impacto) em relação aos seus investimentos (e desinvestimentos) estratégicos em qualquer fase de seus negócios;",
      "Suporte jurídico e estratégico a startups de impacto social escaláveis no que se refere à sua estrutura societária e à implementação dos seus MVPs e planos de negócio, além da assessoria em rodadas de investimento e saídas estratégicas, em diferentes séries e estruturas necessárias de acordo com seu estágio de desenvolvimento;",
      "Apoio na preparação e negociação de memorandos de entendimento; term sheets, mútuos e debêntures conversíveis, acordos de investimentos e de acionistas, e reorganizações societárias, incluindo a internacionalização de atividades e de estruturas societárias (flip);",
      "Desenvolvimento e implementação de estruturas de investimentos nacionais e internacionais;",
      "Assessoria para instituições intermediadoras/dinamizadoras do ecossistema de impacto social, incluindo a estruturação de hubs de impacto, programas de aceleração e veículos de investimento em startups de impacto, além de suporte jurídico na formação de parcerias e joint ventures;",
    ]
  },
  {
    path: 'esg',
    lista: [
      "Diagnóstico ESG para compreender em que estágio de maturidade a companhia ou o projeto de encontram.",
      "Levantamento de impactos significativos para formulação de Matriz de Materialidade ESG, para fins de análise de riscos envolvendo ambiental, social e governança.",
      "Priorização e projeto de implementação de práticas de ESG a partir de uma perspectiva multidisciplinar que prioriza a integração entre agentes do ecossistema de impacto social.",
      "Assessoria para a publicação de Relatórios de Sustentabilidade pela metodologia GRI (Global Reporting Initiative).",
    ]
  },
  {
    path: 'lgpd',
    lista: [
      "Elaboração e execução de programas de compliance com a Lei Geral de Proteção de Dados Pessoais (LGPD) e demais legislação aplicável em matéria de proteção de dados; (vide escopo da proposta) Preparação e revisão de condições contratuais aplicáveis ao tratamento de dados de pessoais;",
      "Cláusula contratual de proteção de dados para inclusão em contratos estratégicos ou revisão de contratos que envolvam tratamento ou transferência de dados pessoais de terceiros;",
      "Consultoria no aconselhamento na preparação e revisão dos procedimentos e práticas de tratamento de dados da organização;",
      "Legal Opinions com análise sobre produtos e serviços oferecidos pela empresa com identificação e mensuração dos riscos, bem como recomendações de boas práticas- (Privacy Impact Assessments e Privacy by Design);",
      "Consultoria para os regulamentos internacionais: General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), Children’s Online Privacy Protection Act (COPPA), Health Insurance Portability and Accountability Act (HIPAA), etc.",
      "Auxílio na definição do Data Protection Officer (DPO) ou responsáveis internos pela governança em privacidade; ",
      "Realização treinamentos para os funcionários das empresas, para prepará-los para o cumprimento das regras internas de compliance;",
    ]
  },
  {
    path: 'direitos-humanos',
    lista: [
      "Consultoria para empresas no desenvolvimento e/ou fortalecimento de departamentos de responsabilidade social e sustentabilidade. Assessoria na implementação nas questões de diversidade e de práticas de direitos humanos nas empresas, analisando em gestões de crise, treinamentos, auditorias preventivas de direitos humanos e elaboração de códigos de conduta e políticas internas, entre outros;",
      "Representação coletiva em face de práticas racistas, homofóbicas, dentre outras violadoras de direitos fundamentais;",
    ]
  },
  {
    path: 'arte-cultura',
    lista: [
      "Acompanhamento de projetos culturais junto a Agência Nacional de Cinema (Ancine), Autorregulamentação Publicitária (Conar), a Agência Nacional de Telecomunicações (Anatel) e aos demais fundos setoriais do Audiovisual;",
      "Elabora e assessoria jurídica para a área editorial e negociações comerciais;",
      "Elaboração e assessoria para contratos de merchandising, campanhas publicitárias e product placement;",
      "Assessoria em direitos autorais e quaisquer questões específicas para espetáculos, shows e eventos;",
      "Assessoria jurídica para patrocinadores, artistas, roteiristas, diretores, produtores, distribuidores e plataformas;",
      "Elaboração de contrato de produção, distribuição, contratação de elenco e demais contratos para produções audiovisuais; ",
      "Assessoria jurídica para a indústria de jogos, abrangendo competidores, times, desenvolvedoras de games e empresas patrocinadoras;",
      "Prestação de assessoria aos principais projetos do setor da mídia e entretenimento, incluído e-Sports, influencers, jogos, filmes, indústria fonográfica (música e discos), NFT, TV (por assinatura e transmissões), teatro, imprensa, publicidade, redes sociais, streaming, vídeo sob demanda (VoD), marcas de entretenimento, produtos de consumo, e entre outros;",
    ]
  },
  {
    path: 'compliance',
    lista: [
      "Compliance Antiassédio;",
      "Normas e procedimentos para adequação e conformidade à Lei 14.457/2022, que estebeleceu novas condições da CIPA, que direciona os empregadores para a promoção da igualdade de gênero, o combate ao assédio sexual e outras formas de violência no ambiente do trabalho;",
      "Compliance Antidiscriminatório;",
      "Normas e procedimentos para a prevenção da discriminação que podem trazer vários beneficios para as empresas, incluindo a melhoria da cultura organizacional e a redução de litigios trabalhistas;",
    ]
  },
];

