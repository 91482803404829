import startupsummit from '../assets/events/startupsummit.jpeg';
import inovativa from '../assets/events/inovativa.jpeg';
import inovativaant from '../assets/events/inovativaANT.jpeg';
import demodayadyen from '../assets/events/demodayadyen.jpeg';
import liderescivico from '../assets/events/encontroliderescivico.jpeg';
import conexaoods from '../assets/events/conexao ods.jpeg';
import piraporiando from '../assets/events/piraporiando.jpeg';
import bndes from '../assets/events/bndesgaragem.jpeg';
import expofavela from '../assets/events/expofavela.jpeg';
import esgecontro from '../assets/events/esgenc.jpeg';
import recnplaycasa0 from '../assets/events/recnplaycasa0.jpeg';
import recnplaycesar from '../assets/events/recnplaycesar.jpeg';
import estrategiaimpacto from '../assets/events/estretegiaimpacto.jpg';


const allEvents = [
  {
    data: '30/11/2022',
    evento: 'ESG: a agenda por trás da sigla',
    atividade: 'Palestrante',
    localidade: 'Recife/PE',	
    responsavel: 'OAB/PE',
    image: esgecontro,
  },
  {
    data: '17/03/2023',
    evento: 'Expo Favela SP 2023',
    atividade: 'Mentor',
    localidade: 'São Paulo/SP',	
    responsavel: 'Expofavela',
    image: expofavela,
  },
  {
    data: '20/04/2023',
    evento: 'Road Show - Programa BNDES Garagem',
    atividade: 'Convidado',
    localidade: 'Recife/PE',
    responsavel: 'BNDES Garagem',
    image: bndes,
  },
  {
    data: '30/05/2023',
    evento: 'Lançamento do Instituto Piraporiando',
    atividade: 'Convidado',
    localidade: 'São Paulo/SP',	
    responsavel: 'Piraporiando',
    image: piraporiando,
  },
  {
    data: '29/07/2023',
    evento: 'Inovativa Experience',
    atividade: 'Mentor',
    localidade: 'São Paulo/SP',	
    responsavel: 'Inovativa Experience',
    image: inovativaant,
  },
  {
    data: '30/08/2023',
    evento: 'Startup Summit 2023',
    atividade: 'Palestrante',
    localidade: 'Florianópolis/SC',	
    responsavel: 'Startup Summit',
    image: startupsummit,
  },
  {
    data: '04/10/2023',
    evento: 'Programa Cais',
    atividade: 'Avaliador',
    localidade: 'Recife/PE',
    responsavel: 'Porto Social',
    image: 'teste',
  },
  /* {
    data: '10/10/2023',
    evento: 'Debate sobre a Lei n. 17.271/2021',
    atividade: 'Palestrante',
    localidade: 'Recife/PE',	
    responsavel: 'OAB/PE',
  }, */
  {
    data: '19/10/2023',
    evento: 'Conexões ODS',
    atividade: 'Mentor',
    localidade: 'Fortaleza/CE',	
    responsavel: 'Coalizão pelo Impacto',
    image: conexaoods,
  },
  {
    data: '24/10/2023',
    evento: 'Encontro de Líderes do Civi-co',
    atividade: 'Convidado',
    localidade: 'São Paulo/SP',
    responsavel: 'Civico',
    image: liderescivico,
  },
  {
    data: '27/10/2023',
    evento: 'Demoday Adyen Accelerator',
    atividade: 'Avaliador',
    localidade: 'São Paulo/SP',	
    responsavel: 'Adyen',
    image: demodayadyen,
  },
  {
    data: '02/12/2023',
    evento: 'Inovativa Experience',
    atividade: 'Mentor',
    localidade: 'São Paulo/SP',	
    responsavel: 'Inovativa Experience',
    image: inovativa,
  },
  {
    data: '18/10/2023',
    evento: "Rec 'n' play",
    atividade: 'Palestrante',
    localidade: 'Casa Zero - PE',	
    responsavel: "Rec 'n' play",
    image: recnplaycasa0,
  },
  {
    data: '18/10/2023',
    evento: "Rec 'n' play",
    atividade: 'Palestrante',
    localidade: 'CESAR Tiradentes - PE',	
    responsavel: "Rec 'n' play",
    image: recnplaycesar,
  },
  {
    data: '24/11/2023',
    evento: "Estratégia nacional de economia de impacto e a lei de negócios de impacto",
    atividade: 'Palestrante',
    localidade: 'Plenária da OAB - PE',	
    responsavel: "OAB/PE",
    image: estrategiaimpacto,
  },
]

const sortEvents = (eventsArr) => {
  // Custom date parsing function for Brazilian date format (dd/mm/yyyy)
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day); // Months are zero-based in JavaScript
  };

  // Get the current date
  const currentDate = new Date();

  // Sort the events array by the difference between event date and current date
  eventsArr.sort((a, b) => {
    const dateA = parseDate(a.data);
    const dateB = parseDate(b.data);

    const diffA = Math.abs(dateA - currentDate);
    const diffB = Math.abs(dateB - currentDate);

    // Check if the event date is in the future
    const isDateAFuture = dateA > currentDate;
    const isDateBFuture = dateB > currentDate;

    // If one date is in the future, it should come before the other
    if (isDateAFuture && !isDateBFuture) {
      return -1;
    } else if (!isDateAFuture && isDateBFuture) {
      return 1;
    }

    // If both dates are in the past or both in the future, sort by proximity
    return diffA - diffB;
  });

  return eventsArr;
};

export const sortedEvents = sortEvents(allEvents);