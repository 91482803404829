import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AreasAtuacao from './pages/AreasAtuacao';
import Conteudo from './pages/Conteudo';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/areasatuacao",
    element: <AreasAtuacao />,
    errorElement: <ErrorPage />,
  },
  /* {
    path: "/conteudo",
    element: <Conteudo />,
    errorElement: <ErrorPage />,
  }, */
  {
    path: "/conteudo/:servico",
    element: <Conteudo />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Navbar",
    element: <Navbar />,
    /* errorElement: <ErrorPage />, */
  },
  {
    path: "/Footer",
    element: <Footer />,
    /* errorElement: <ErrorPage />, */
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);