import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/R_lima.png";

function ErrorPage() {
  return (
    <div className="bg-azul h-screen text-center flex flex-col items-center justify-center py-10">
      <img src={logo} alt="logo R_lima" className="w-1/2" />
      <h1 className="uppercase p-10 text-branco text-center text-4xl font-semibold">página não encontrada!</h1>
      <Link to="/" className="bg-verde p-2 uppercase text-lg font-semibold hover:font-bold hover:p-3 transition-all ease-linear">Home</Link>
    </div>
  )
}

export default ErrorPage;