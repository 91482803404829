import React, { useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Parceiros from "../components/Carousel";
import ParceirosMobile from "../components/CarouselMobile";

import rightIcon from "../assets/right-outlined.svg";
import grafCircle from "../assets/R.Lima_Grafismos-03.png";
import bgPilares from "../assets/bg-sessaopilares.jpg";
import bgNoticias from "../assets/bg-sessaonoticias.jpg";

import videobanner from "../assets/VideoSite-1920x540.mp4";
import EventsCarousel from "../components/EventsCarousel";
import BlogCarousel from "../components/BlogCarousel";

import "./styles.css";

import logogail from "../assets/GAIL.png";
import rlimafoto from "../assets/rlima.jpg";

function Home() {
  const screenSize = window.innerWidth;
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Navbar />
      <div id="inicioHome" className="flex-1 max-md:mt-28">
        <HashLink smooth to="/#inicioHome">
          <div
            id="scrollTop"
            className="bg-branco/50 p-2 fixed bottom-4 right-4 cursor-pointer z-50"
          >
            <Icon
              icon="ant-design:left-outlined"
              width={20}
              height={20}
              className="rotate-90 text-azul"
            />
          </div>
        </HashLink>
        <div className="flex-1 ml-auto w-11/12 xl:ml-24 xl:w-full xl:pr-20 max-md:ml-0 max-md:w-full">
          <div
            id="VideoBanner"
            className="flex bg-azul bg-fixed overflow-clip w-full ml-0"
          >
            <video
              src={videobanner}
              type="video/mp4"
              style={{ maxWidth: "100%", margin: "0" }}
              playsInline
              muted
              loop
              ref={videoEl}
              className="m-0"
            />
          </div>
          <div
            id="essencia"
            className="introcontainer bg-right bg-fixed flex h-72 flex-row items-center justify-between max-md:flex-col max-md:h-fit py-5 max-lg:px-10 px-28 text-xl text-azul font-medium font-titles"
          >
            <div className="flex-1 hover:animate-pulse hover:translate-x-2 transition-all ease-linear cursor-pointer mx-6 px-6 my-6">
              <div className="flex items-center">
                <h1>ESSÊNCIA</h1>
                <img
                  src={rightIcon}
                  alt="ícone de uma seta estilizada apontando para a direita"
                  className="text-verdeSeta ml-2 scale-90"
                />
              </div>
              <p className="text-sm">
                Nossa essência está enraizada no compromisso de desenvolver
                soluções jurídicas inovadoras e personalizadas para o mercado de
                inovação e impacto socioambiental. Com presença em todo o
                Brasil, com unidades físicas em Recife/PE e São Paulo/SP, nossa
                missão vai além da advocacia tradicional, nossa missão é
                impactar.
              </p>
            </div>
            <div className="flex-1 hover:animate-pulse hover:translate-x-2 transition-all ease-linear cursor-pointer mx-6 px-6 my-6">
              <div className="flex items-center">
                <h1>ATUAÇÃO</h1>
                <img
                  src={rightIcon}
                  alt="ícone de uma seta estilizada apontando para a direita"
                  className="text-verdeSeta ml-2 scale-90"
                />
              </div>
              <p className="text-sm">
                Nossa atuação abrange uma ampla gama de áreas jurídicas, com
                foco principal nas Organizações da Sociedade Civil (OSC),
                Negócios de Impacto Socioambiental, ESG e Arte, Cultura e
                Entretenimento. Trabalhamos incansavelmente para fornecer
                soluções jurídicas sólidas e personalizadas que ajudam nossos
                clientes a atingir seus objetivos de impacto.
              </p>
            </div>
            {/* <div className="flex-1 hover:animate-pulse hover:translate-x-2 transition-all ease-linear cursor-pointer mx-6 px-6 my-6">
              <div className="flex items-center ">
                <h1>
                  RESPONSABILIDADE<br />SOCIAL
                </h1>
                <img src={rightIcon} alt="ícone de uma seta estilizada apontando para a direita" className="text-verdeSeta ml-2 scale-90" />
              </div>
              <p className="text-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate suscipit vero cum omnis. Rerum repudiandae unde corrupti temporibus maxime quo expedita.</p>
            </div> */}
          </div>

          <div
            className="bg-brancoAzulado py-5 bg-no-repeat bg-center bg-cover relative overflow-clip"
            style={{ backgroundImage: `url(${bgPilares})` }}
          >
            <section className="text-azul my-10 flex justify-center ">
              <h1 className="text-azul text-4xl max-md:text-2xl font-semibold">
                PILARES DA ATUAÇÃO
              </h1>
            </section>

            <div className="text-azul text-2xl max-md:text-lg font-bold mx-40 my-20 max-md:mx-10 max-md:my-5 flex max-md:flex-col items-center justify-evenly font-titles ">
              <h2 className="text-center mx-3 max-md:my-3">
                TERCEIRO <br />
                SETOR
              </h2>
              <h2 className="text-center mx-3 max-md:my-3">ESG</h2>
              <h2 className="text-center mx-3 max-md:my-3">
                ARTE, CULTURA E<br /> ENTRETENIMENTO
              </h2>
              <h2 className="text-center mx-3 max-md:my-3">
                NEGÓCIOS <br />
                DE IMPACTO
              </h2>
            </div>

            <section className="text-azul text-2xl max-md:text-xl font-normal my-10 flex justify-center">
              <HashLink to="/AreasAtuacao#intro">
                <button className="font-titles font-medium px-4 py-3 mx-8 border-2 border-verdeSeta hover:bg-verdeSeta hover:shadow-md hover:text-branco transition-all ease-linear">
                  Conheça nossas áreas de atuação
                </button>
              </HashLink>
            </section>
          </div>

          <section id="eventos" className="pb-10 max-md:pb-0">
            <img
              src={grafCircle}
              alt="imagem decorativa"
              className="absolute -z-10 -left-80 scale-125 opacity-70 max-md:hidden"
            />
            <h1 className="text-azul text-4xl max-md:text-2xl font-semibold text-center mt-10 mb-16">
              EVENTOS
            </h1>
            <div className="mx-5 md:mx-14 lg:ml-20 lg:mr-28 ">
              <EventsCarousel />
            </div>
          </section>

          <section
            id="noticias"
            className="bg-bottom bg-cover bg-no-repeat flex-1 pb-48 pt-4"
            style={{ backgroundImage: `url(${bgNoticias})` }}
          >
            <a href="https://rlimaadv.com.br/blog">
              <h1 className="text-azul text-4xl font-semibold uppercase text-center mt-10 mb-16">
                Notícias
              </h1>
            </a>

            <div className="mx-10">
              <BlogCarousel />
              <div className="text-center py-4 max-sm:pt-10">
                <a
                  href="https://rlimaadv.com.br/blog"
                  className="text-xl font-semibold font-titles px-4 py-3 mx-8 border-2 border-verdeSeta hover:bg-verdeSeta hover:shadow-md hover:text-branco transition-all ease-linear"
                >
                  Acesse nosso blog
                </a>
              </div>
            </div>
          </section>

          <section id="liderancas" className="lg:mx-32 mx-10 my-16 relative">
            <img
              src={grafCircle}
              alt="imagem decorativa"
              className="absolute -z-10 -right-96 -bottom-72 scale-125 opacity-20 max-md:hidden"
            />
            <h1 className="text-azul text-4xl max-md:text-2xl font-titles font-semibold text-center mt-5 mb-16 uppercase">
              Liderança
            </h1>
            <div className="md:flex items-center justify-center mx-auto">
              <div className="flex-1 mr-5 max-w-md max-md:mb-5">
                <img src={rlimafoto} alt="foto de Rafael Lima" />
              </div>
              <div className="flex-1">
                <p className="text-base lg:text-xl xl:text-2xl ml-1 mb-2 text-azul">
                  <strong>Rafael Lima Castelo Branco Ferreira</strong>
                </p>
                <ul className=" text-azul list-disc pl-4 text-sm/tight lg:text-base/tight xl:text-lg/tight">
                  <li className="my-1.5">
                    Graduado em Direito pela Universidade Católica de
                    Pernambuco.
                  </li>
                  <li className="my-1.5">
                    Pós-graduado em Direito Administrativo pela Universidade
                    Estácio de Sá | Complexo de Ensino Renato Saraiva (CERS).
                  </li>
                  <li className="my-1.5">
                    Pós-graduado em Compliance e Integridade Corporativa pela
                    Pontifícia Universidade Católica de Minas Gerais.
                  </li>
                  <li className="my-1.5">
                    MBA em Gestão de Organizações e Negócios de Impacto Social
                    do Instituto Legado.
                  </li>
                  <li className="my-1.5">
                    Certificado em Relatos de Sustentabilidade pela GRI –{" "}
                    <span className="italic font-semibold">
                      Global Reporting Initiative.
                    </span>
                  </li>
                </ul>
                <div className="flex items-center mt-8">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/rlima.adv"
                    className="mx-3"
                  >
                    <Icon
                      icon="ri:instagram-line"
                      width={40}
                      className="text-azul"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/rafael-lima-adv/"
                    className="mx-3"
                  >
                    <Icon
                      icon="ri:linkedin-fill"
                      width={40}
                      className="text-azul"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section id="parceiros">
            {screenSize > "640" ? <Parceiros /> : <ParceirosMobile />}
          </section>

          <section
            id="internacional"
            className="pb-16 pt-10 introcontainer bg-right bg-fixed max-lg:px-10 px-28"
          >
            <h1 className="text-azul text-4xl max-md:text-2xl font-titles font-semibold text-center mt-5 mb-12 uppercase">
              Parcerias Internacionais
            </h1>
            <div className="w-3/4 md:w-1/4 mx-auto">
              <a href="https://gailnet.org/" target="_blank" rel="noreferrer">
                <img
                  src={logogail}
                  alt="Logo GAIL - Global Alliance of Impact Lawyers"
                />
              </a>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
