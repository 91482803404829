import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./swiperstyles.css";

import fetchBlogPosts from "../utils/fetchPosts";
import { defaultData } from "../data/Blog";

function BlogCarousel() {
  const [posts, setPosts] = useState(defaultData);
  /* const betterClearHTMLTags = (strToSanitize) => {
    let myHTML = new DOMParser().parseFromString(strToSanitize, "text/html");
    return myHTML.body.textContent || "";
  }; */

  useEffect(() => {
    fetchBlogPosts()
      .then((response) => {
        setPosts(response);
      })
      .catch((error) => console.log(error));
  }, []);

  const generateSlide = (blogPost) => {
    return (
      <SwiperSlide className="blogslide">
        <div className="flex flex-col items-center justify-center mx-5">
          <div className="flex max-w-sm max-h-52 overflow-clip mb-4">
            <img
              src={blogPost._embedded["wp:featuredmedia"][0].source_url}
              alt="Imagem notícias"
              className="object-cover"
            />
          </div>

          <article className="font-titles max-w-xs">
            <a
              href={blogPost.link}
              target="_blank"
              rel="noreferrer"
              className="text-azul text-lg font-semibold flex flex-col hover:translate-x-2 transition-all ease-linear cursor-pointer"
            >
              <h1 className="text-azul my-2 max-md:my-3 text-xl/tight font-semibold">
                {blogPost.title.rendered}
              </h1>
              <p className="flex">
                Leia já <h1 className="text-verdeSeta ml-1 font-thin">❯</h1>
              </p>
            </a>
            {/* <a
              href={blogPost.link}
              target="_blank"
              rel="noreferrer"
              className="text-azul text-lg font-semibold flex hover:translate-x-2 transition-all ease-linear cursor-pointer"
            ></a> */}
          </article>
        </div>
      </SwiperSlide>
    );
  };

  return (
    <Swiper
      modules={[Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2.9,
        },
      }}
      spaceBetween={50}
      initialSlide={1}
      slidesPerView={2.9}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      navigation={true}
      centeredSlides
      className="blogswiper my-16 max-sm:my-0 max-sm:mb-10"
    >
      {posts.map((post) => {
        return generateSlide(post);
      })}
    </Swiper>
  );
}

export default BlogCarousel;
