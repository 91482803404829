import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./swiperstyles.css";

import { sortedEvents } from "../data/Events";

function EventsCarousel() {
  const generateSlide = (eventObj) => {
    return (
      <SwiperSlide>
        <div className="flex justify-center items-center text-azul my-5 mx-5 max-md:px-5 lg:mx-10">
          <div className="bg-slate-500 h-40 w-40 max-w-xs max-md:h-32 max-md:w-32 overflow-clip">
            <img
              src={eventObj.image}
              alt="foto do evento"
              className="object-fill"
            />
          </div>
          <div className="ml-3 flex-1">
            <h1 className="text-2xl/none max-md:text-lg/tight font-semibold mb-2">
              {eventObj.evento}
            </h1>
            <p className="text-lg max-md:text-sm font-medium">
              {eventObj.atividade}
            </p>
            <p className="text-lg max-md:text-sm font-medium">
              {eventObj.data}
            </p>
            <p className="text-lg max-md:text-sm font-medium">
              {eventObj.localidade}
            </p>
          </div>
        </div>
      </SwiperSlide>
    );
  };

  return (
    <Swiper
      modules={[Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 2,
        },
      }}
      spaceBetween={10}
      slidesPerView={2}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      navigation={true}
      loop
      className="h-max max-md:mb-12"
    >
      {sortedEvents.map((event) => {
        return generateSlide(event);
      })}
    </Swiper>
  );
}

export default EventsCarousel;
