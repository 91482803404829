import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ConteudoDetails from "../components/ConteudoDetails";

import { conteudo } from "../data/conteudoData";

import bgtopo from "../assets/bg_area-08minified.png";

function Conteudo() {
  const { servico } = useParams();

  const selectedConteudo = conteudo.find((item) => item.path === servico);

  return (
    <>
      <Navbar />
      <div>
        <section className="flex-1 md:ml-24 w-full">
          <div
            className="bg-cover bg-center shadow-sm py-8 h-48 flex justify-center items-center max-md:mt-28"
            style={{ backgroundImage: `url(${bgtopo})` }}
          >
            <h1 className="text-azul text-4xl font-semibold font-titles max-sm:text-2xl">
              ÁREAS DE ATUAÇÃO
            </h1>
          </div>
          <ConteudoDetails dataToUse={selectedConteudo} />
        </section>
      </div>
      <div className="md:ml-24 relative bottom-0 inset-x-0">
        <Footer />
      </div>
    </>
  );
}

export default Conteudo;
