import React, { useState } from "react";
import "../index.css";

import Mensagem from "../assets/mensagem.png";
import Facebook from "../assets/facebook_icon.svg";
import Instagram from "../assets/instagram_icon.svg";
import Linkedin from "../assets/linkedin_icon.svg";
import RLima from "../assets/R_lima.png";
import Ponteia from "../assets/ponteia.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* aqui inicia logica de abrir o menu */}
      <div
        className={`slide-container-horizontal ${
          isOpen ? "slide-right" : "slide-left"
        } bg-azul text-white h-full fixed z-50`}
      >
        <nav className="bg-azul w-24 h-screen flex flex-col max-md:flex-row fixed justify-between items-center pt-8 pb-32 max-md:w-full max-md:top-0 max-md:h-28 max-md:pr-6 max-md:pb-10">
          <div className="w-full flex items-center justify-center py-4 max-md:w-48 max-md:ml-5">
            <Link to="/">
              <img
                src={RLima}
                alt="LogoRLima"
                className={`object-contain ${
                  isOpen
                    ? "scale-[2] translate-x-16 duration-500"
                    : "scale-90 duration-500"
                } max-md:scale-100 max-md:translate-x-0 transition-all ease-in-out`}
              />
            </Link>
          </div>

          <div
            className="flex text-branco px-3 max-md:mt-0 max-md:items-center justify-center"
            id="menu"
          >
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="w-12 text-sm/none active:animate-pulse transition-all ease-in-out uppercase"
            >
              {isOpen ? (
                <Icon
                  icon="teenyicons:left-solid"
                  width={48}
                  height={48}
                  className="max-md:rotate-90 pb-3"
                />
              ) : (
                <Icon icon="mdi-light:menu" width={48} height={48} />
              )}
              Menu
            </button>
          </div>
          <div id="iconesredes" className="max-md:hidden">
            <div
              id="mensagem"
              className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:contato@rlimaadv.com.br"
              >
                <img src={Mensagem} alt="Mensagem" className="object-contain" />
              </a>
            </div>

            <div
              id="linkedin"
              className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/r-lima-adv/"
              >
                <img src={Linkedin} alt="Linkedin" className="object-contain" />
              </a>
            </div>

            <div
              id="instagram"
              className="flex p-1 items-center justify-center w-10 h-10 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/rlima.adv"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  className="object-contain"
                />
              </a>
            </div>

            <div
              id="facebook"
              className=" flex p-2 items-center justify-center w-10 h-10 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/rafaelima.adv"
              >
                <img src={Facebook} alt="Facebook" className="object-contain" />
              </a>
            </div>
          </div>
          <div className="w-full max-md:hidden">
            <a target="_blank" rel="noreferrer" href="https://ponteia.com/">
              <img src={Ponteia} alt="LogoPonteia" className="object-contain" />
            </a>
          </div>
        </nav>
        <div className="p-10 ml-32 mt-32 max-md:ml-0">
          {/* <button type='button' onClick={() => setIsOpen(false)} className='font-black text-2xl absolute top-2 right-4'>X</button> */}
          <ul className="text-left text-xl font-semibold">
            <li className="my-3 hover:text-verde transition-all ease-in-out">
              <HashLink to="/areasatuacao#intro">
                <h3>Áreas de Atuação</h3>
              </HashLink>
            </li>
            <li className="my-3 hover:text-verde transition-all ease-in-out">
              <HashLink to="/areasatuacao#expertise">
                <h3>Setores de Expertise</h3>
              </HashLink>
            </li>
            <li className="my-3 hover:text-verde transition-all ease-in-out">
              <HashLink to="/#essencia">
                <h3>Essência</h3>
              </HashLink>
            </li>
            <li className="my-3 hover:text-verde transition-all ease-in-out">
              <HashLink to="/#eventos">
                <h3>Eventos</h3>
              </HashLink>
            </li>
            <li className="my-3 hover:text-verde transition-all ease-in-out">
              <HashLink to="/#noticias">
                <h3>Notícias</h3>
              </HashLink>
            </li>
          </ul>
          <div
            id="iconesredesmobi"
            className="md:hidden flex items-center my-10"
          >
            <div
              id="mensagem"
              className="flex p-1 items-center justify-center w-12 h-12 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="contato@rlimaadv.com.br"
              >
                <img src={Mensagem} alt="Mensagem" className="object-contain" />
              </a>
            </div>

            <div
              id="linkedin"
              className="flex p-1 items-center justify-center w-12 h-12 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/r-lima-adv/"
              >
                <img src={Linkedin} alt="Linkedin" className="object-contain" />
              </a>
            </div>

            <div
              id="instagram"
              className="flex p-1 items-center justify-center w-12 h-12 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/rlima.adv"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  className="object-contain"
                />
              </a>
            </div>

            <div
              id="facebook"
              className=" flex p-2 items-center justify-center w-12 h-12 mx-auto my-3 hover:-translate-y-1 hover:border-2 hover:border-white rounded-full transition-all ease-in-out"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/rafaelima.adv"
              >
                <img src={Facebook} alt="Facebook" className="object-contain" />
              </a>
            </div>
          </div>
          <div className="w-full md:hidden">
            <div className="w-40 flex justify-center items-center mx-auto">
              <a target="_blank" rel="noreferrer" href="https://ponteia.com/">
                <img
                  src={Ponteia}
                  alt="LogoPonteia"
                  className="object-contain"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
