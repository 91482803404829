import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";

function Footer() {
  // essa função não retornava nada, apenas logava. ideal aproveitar o retorno para dar algum feedback visual para o usuário.
  /* const handleCopyText = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Texto copiado!');
        return 'Texto copiado!';
      })
      .catch((error) => {
        console.error('Erro ao copiar o texto:', error);
        return 'Erro ao copiar o texto:';
      });
  }; */

  const [copiedMessage, setCopiedMessage] = useState("");

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessage("Texto copiado!");
        setTimeout(() => {
          setCopiedMessage("");
        }, 1000); // Limpa o estado após 5 segundos (5000 milissegundos).
      })
      .catch((error) => {
        setCopiedMessage("Erro ao copiar o texto");
        console.error("Erro ao copiar o texto:", error);
        setTimeout(() => {
          setCopiedMessage("");
        }, 2000);
      });
  };

  return (
    <footer className="flex justify-between items-end max-md:flex-col max-md:items-center w-full px-5 pr-32 pl-32 pb-10 pt-10 max-md:px-12 bg-brancoAzulado">
      <div className="max-md:mb-4">
        <ul className="text-left uppercase text-azul text-lg font-semibold list-image-go">
          <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/areasatuacao#intro">
              <h3>Áreas de Atuação</h3>
            </HashLink>
          </li>
          <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/areasatuacao#expertise">
              <h3>Setores de Expertise</h3>
            </HashLink>
          </li>
          {/* <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/#parceiros">
              <h3>Responsabilidade Social</h3>
            </HashLink>
          </li> */}
          <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/#essencia">
              <h3>Essência</h3>
            </HashLink>
          </li>
          <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/#eventos">
              <h3>Eventos</h3>
            </HashLink>
          </li>
          <li className="my-4 hover:translate-x-2 transition-all ease-linear cursor-pointer">
            <HashLink to="/#noticias">
              <h3>Notícias</h3>
            </HashLink>
          </li>
        </ul>
      </div>

      <div>
        <div className="text-azul text-base font-semibold font-titles text-end mb-6">
          <h3 className="uppercase text-verde mb-6">Onde estamos</h3>
          <div className="mb-4">
            <h5 className="uppercase font-bold">São paulo</h5>
            <p
              className="cursor-grab"
              onClick={() =>
                handleCopyText(
                  "Endereço Rua Virgílio de Carvalho Pinto, 445, Pinheiros, São Paulo/SP, CEP 05.405-105"
                )
              }
            >
              Rua Virgílio de Carvalho Pinto, 445,
              <br /> Pinheiros, São Paulo/SP, CEP 05.405-105
            </p>
          </div>
          <div>
            <h5 className="uppercase font-bold">Recife</h5>
            <p
              className="cursor-grab "
              onClick={() =>
                handleCopyText(
                  "Endereço Rua do Futuro, 564, Graças, Recife/PE, CEP 52.050-005"
                )
              }
            >
              Rua do Futuro, 564, Graças,
              <br /> Recife/PE, CEP 52.050-005
            </p>
          </div>
          <div className="flex text-azul bg-black/50 uppercase font-bold absolute">
            {copiedMessage}
          </div>
        </div>

        <div className="text-azul text-lg font-semibold font-titles text-right max-sm:mb-8">
          <p
            className="cursor-grab"
            onClick={() => handleCopyText("contato@rlimaadv.com.br")}
          >
            contato@rlimaadv.com.br
          </p>
          <p
            className="cursor-grab text-lg"
            onClick={() => handleCopyText("+55 11 91164‑7619")}
          >
            Tel.: +55 11 91164‑7619
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
