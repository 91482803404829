import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
/* import Accordion from "./Accordion"; */

function ConteudoDetails(props) {
  const dataToUse = props.dataToUse;
  const currentPath = dataToUse.path;
  const [currentTitle, setCurrentTitle] = useState("");
  // console.log(dataToUse, 'dataToUse');
  // console.log(currentPath, 'currentPath');

  // essa função controla o titulo que será exibido
  useEffect(() => {
    switch (currentPath) {
      case "direito-regulatorio":
        setCurrentTitle("Direito Público e Regulatório");
        break;
      case "direito-terceiro":
        setCurrentTitle("Organizações da Sociedade Civil e Terceiro Setor");
        break;
      case "direito-ambiental":
        setCurrentTitle("Direito Ambiental");
        break;
      case "direito-empresarial":
        setCurrentTitle("Societário e Empresarial para Negócios de Impacto");
        break;
      case "contratos-negocios":
        setCurrentTitle("Contratos e modelagem de novos negócios");
        break;
      case "propriedade-intelectual":
        setCurrentTitle("Propriedade Intelectual");
        break;
      case "impact-investing":
        setCurrentTitle("Impact Investing e Venture Capital");
        break;
      case "esg":
        setCurrentTitle("ESG - Environmental, Social and Governance");
        break;
      case "direitos-humanos":
        setCurrentTitle("Direitos Humanos");
        break;
      case "arte-cultura":
        setCurrentTitle("Arte, Cultura e Entretenimento");
        break;
      case "compliance":
        setCurrentTitle("Compliance e Gestão de Riscos Jurídicos");
        break;
      default:
        setCurrentTitle("Privacidade e Proteção de Dados");
    }
  }, [currentPath]);

  return (
    <div className="flex max-md:flex-col-reverse">
      <div className=" px-7 pt-10 pb-6 md:pb-20 md:w-1/4 w-full text-center bg-cinza md:text-left font-semibold min-h-max max-md:h-fit">
        <h1 className="text-2xl/tight font-bold mb-6">Áreas de atuação</h1>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/direito-regulatorio">
            <button
              className={
                currentPath === "direito-regulatorio"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Direito Público e Regulatório;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/direito-terceiro">
            <button
              className={
                currentPath === "direito-terceiro"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              OSC e Terceiro Setor;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/direito-ambiental">
            <button
              className={
                currentPath === "direito-ambiental"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Direito Ambiental;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/direito-empresarial">
            <button
              className={
                currentPath === "direito-empresarial"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Empresarial e Societário;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/contratos-negocios">
            <button
              className={
                currentPath === "contratos-negocios"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Contratos e modelagem de novos negócios;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/propriedade-intelectual">
            <button
              className={
                currentPath === "propriedade-intelectual"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Propriedade Intelectual;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/impact-investing">
            <button
              className={
                currentPath === "impact-investing"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Impact Investing e Venture Capital;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/esg">
            <button
              className={
                currentPath === "esg"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              ESG;
            </button>
          </HashLink>
        </div>

        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/lgpd">
            <button
              className={
                currentPath === "lgpd"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              LGPD;
            </button>
          </HashLink>
        </div>
        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/direitos-humanos">
            <button
              className={
                currentPath === "direitos-humanos"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Direitos Humanos;
            </button>
          </HashLink>
        </div>
        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/arte-cultura">
            <button
              className={
                currentPath === "arte-cultura"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Arte, Cultura e Entretenimento;
            </button>
          </HashLink>
        </div>
        <div className="text-lg/tight my-4">
          <HashLink to="/conteudo/compliance">
            <button
              className={
                currentPath === "compliance"
                  ? "font-bold md:text-left"
                  : "hover:font-bold transition-all ease-in-out text-center md:text-left"
              }
            >
              Compliance e Gestão de Riscos Jurídicos;
            </button>
          </HashLink>
        </div>
      </div>

      <section className=" flex-1 pt-10 pb-12 max-md:pb-10 md:pr-12">
        <h2 className="text-3xl max-md:text-2xl font-semibold font-titles md:pl-8 max-md:text-center">
          {currentTitle}
        </h2>
        <h1 className="text-xl font-bold font-titles md:pl-14 mt-6 max-md:text-center">
          Principais serviços
        </h1>
        <ul className="list-image-diamond text-base/tight px-20 py-2 font-normal">
          {dataToUse.lista.map((item) => {
            return (
              <li className="my-6" key={item}>
                {item}
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
}

export default ConteudoDetails;
