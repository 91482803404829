import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react";
import { useInView } from "react-intersection-observer";

import "./animations.css";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import TerceiroSetor from "../assets/TerceiroSetor.png";
import ESG from "../assets/ESG.png";
//import DireitoHumanos from "../assets/DiretosHumanos.png";
import ImpactoSocial from "../assets/ImpactoSocial.png";
import ArteCulturaEnt from "../assets/ArteCultEnt.png";

//Paginas que leva aos Conteudos

function Areas() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust this value as needed.
  });

  useEffect(() => {
    if (inView) {
      const elementsToAnimate = document.querySelectorAll(".fade-in");
      elementsToAnimate.forEach((element) => {
        element.classList.add("fade-in-animation");
      });
    }
  }, [inView]);

  return (
    <>
      <Navbar />
      <div id="intro" className="overflow-clip">
        <section
          id="areasCards"
          className="md:ml-24 xl:pl-24 max-md:mt-20 w-11/12 max-md:w-full border border-white font-sans md:pb-16"
        >
          <h1 className="text-azul text-4xl max-sm:text-2xl font-semibold font-titles text-center my-20 max-sm:mt-28">
            ÁREAS DE ATUAÇÃO
          </h1>
          <div className="grid grid-cols-3 max-md:grid-cols-2 max-[480px]:grid-cols-1 ml-32 mr-20 gap-10 max-md:mx-10 max-md:justify-items-center">
            <HashLink to="/conteudo/direito-regulatorio#top">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Direito Público</h1>
                <div className="flex items-end">
                  <h1>e Regulatório</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/direito-terceiro">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>OSC e</h1>
                <div className="flex items-end">
                  <h1>Terceiro Setor</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/direito-ambiental">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <div className="flex items-end">
                  <h1>Direito Ambiental</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/direito-empresarial">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Negócios de</h1>
                <div className="flex items-end">
                  <h1>Impacto</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/contratos-negocios">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Contratos e modelagem</h1>
                <div className="flex items-end">
                  <h1>de novos negócios</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/propriedade-intelectual">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Propriedade</h1>
                <div className="flex items-end">
                  <h1>Intelectual</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/impact-investing">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Impact Investing</h1>
                <div className="flex items-end">
                  <h1>e Venture Capital</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/esg">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <div className="flex items-end">
                  <h1>ESG</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/lgpd">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <div className="flex items-end">
                  <h1>LGPD</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>
            <HashLink to="/conteudo/direitos-humanos">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <div className="flex items-end">
                  <h1>Direitos Humanos</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/arte-cultura">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Arte, Cultura </h1>
                <div className="flex items-end">
                  <h1>e Entretenimento</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>

            <HashLink to="/conteudo/compliance">
              <div className="bg-azul max-w-xs h-28 max-md:w-48 hover:shadow-md hover:-translate-y-2 transition-all ease-linear flex flex-col justify-center items-start px-5 text-branco text-2xl max-xl:text-lg max-lg:text-base">
                <h1>Compliance e Gestão</h1>
                <div className="flex items-end">
                  <h1>de Riscos Jurídicos</h1>
                  <Icon
                    icon="ant-design:right-outlined"
                    className="text-verdeSeta ml-1"
                  />
                </div>
              </div>
            </HashLink>
          </div>
        </section>
        <section
          id="expertise"
          className="md:mx-20 w-full border border-white font-sans pb-16"
        >
          <div className="text-azul mt-16 mb-20 flex justify-center items-center">
            <div className="h-px w-full bg-azul shadow-sm"></div>
            <h1 className="text-azul text-2xl md:text-3xl bg-branco font-medium pb-2 px-6 absolute w-fit max-md:text-center">
              Setores de Expertise
            </h1>
          </div>

          <div className="bg-branco flex items-center justify-center pb-16 mx-28 max-md:mx-0 max-sm:flex-wrap">
            <div className="md:flex">
              <div
                className="fade-in flex flex-col items-center justify-center w-36 max-sm:w-28 max-md:mb-8 md:mx-6 mx-3"
                ref={ref}
              >
                <img
                  src={TerceiroSetor}
                  alt="Imagem notícias"
                  className="object-contain"
                />
                <h1 className="text-azul text-base/tight md:text-xl/tight text-center font-medium">
                  TERCEIRO SETOR
                </h1>
              </div>

              <div
                className="fade-in flex flex-col items-center justify-center w-36 max-sm:w-28 max-md:mb-8 md:mx-6 mx-3"
                ref={ref}
              >
                <img
                  src={ESG}
                  alt="Imagem notícias"
                  className="object-contain"
                />
                <h1 className="text-azul text-base/tight md:text-xl/tight text-center align-middle font-medium pt-5 w-full">
                  ESG
                </h1>
              </div>
            </div>
            <div className="md:flex">
              {/* <div className="fade-in flex flex-col items-center justify-center w-36 max-sm:w-28 max-md:mb-8 md:mx-6 mx-3" ref={ref}>
                <img src={DireitoHumanos} alt="Imagem notícias" className="object-contain" />
                <h1 className="text-azul text-base/tight md:text-xl/tight text-center font-medium">DIREITOS HUMANOS</h1>
              </div> */}

              <div
                className="fade-in flex flex-col items-center justify-center w-36 max-sm:w-28 max-md:mb-8 md:mx-6 mx-3"
                ref={ref}
              >
                <img
                  src={ArteCulturaEnt}
                  alt="Imagem notícias"
                  className="object-contain p-2"
                />
                <h1 className="text-azul text-base/tight md:text-xl/tight text-center font-medium">
                  ARTE CULTURA E ENTRETENIMENTO
                </h1>
              </div>

              <div
                className="fade-in flex flex-col items-center justify-center w-36 max-sm:w-28 max-md:mb-8 md:mx-6 mx-3"
                ref={ref}
              >
                <img
                  src={ImpactoSocial}
                  alt="Imagem notícias"
                  className="object-contain"
                />
                <h1 className="text-azul text-base/tight md:text-xl/tight text-center font-medium">
                  NEGÓCIOS
                  <br /> DE IMPACTO
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="md:ml-24 w-full max-md:ml-0 md:pr-20">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Areas;
