import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";

import "./Button.css";

import Baanko from "../assets/Baanko.png";
import Civi from "../assets/Civi-co.png";
import Expofavela from "../assets/Expofavela.png";
import Favela from "../assets/Favelalogo.png";
import Hubsocial from "../assets/Hubsocial.png";
import Inovativa from "../assets/Inovativa.png";
import Semente from "../assets/Semente.png";
import Probono from "../assets/Pro_Bonologo_Site.png";

const CarouselMobile = () => {
  useEffect(() => {
    new Glide(".glide", {
      type: "carousel",
      autoplay: false,
      /* animationTimingFunc: 'linear',
      animationDuration: 7000, */
      perView: 1,
      startAt: 0,
      gap: 30,
      /* peek: 50, */
      focusAt: "center",
      keyboard: true,
    }).mount();
  }, []);

  return (
    <div id="parceiros" className="glide relative bg-azul pt-6">
      <h1 className="text-branco text-center text-xl mx-3 font-medium mb-3 mt-10 uppercase font-titles">
        Apoio a Dinamizadoras de Impacto Social
      </h1>
      <div
        className="glide__arrows my-2 text-center text-verdeSeta absolute left-1 z-40 bottom-5"
        data-glide-el="controls"
      >
        <button
          className="glide__arrow glide__arrow--prev scale-150"
          data-glide-dir="<"
        >
          <Icon icon="ant-design:left-outlined" width={30} height={30} />
        </button>
      </div>
      <div
        className="glide__arrows my-2 text-center text-verdeSeta absolute right-1 z-40 bottom-5"
        data-glide-el="controls"
      >
        <button
          className="glide__arrow glide__arrow--next scale-150"
          data-glide-dir=">"
        >
          <Icon icon="ant-design:right-outlined" width={30} height={30} />
        </button>
      </div>
      <div className="glide__track my-6 relative" data-glide-el="track">
        <ul className="glide__slides flex items-center justify-center">
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img
                src={Hubsocial}
                alt="logo Hubsocial"
                className="object-contain"
              />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img src={Baanko} alt="logo Baanko" className="object-contain" />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img src={Civi} alt="logo Civi-co" className="object-contain" />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img
                src={Expofavela}
                alt="logo Expofavela"
                className="object-contain"
              />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img src={Favela} alt="logo Fa.vela" className="object-contain" />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img
                src={Inovativa}
                alt="logo Inovativa"
                className="object-contain"
              />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img
                src={Semente}
                alt="logo Semente"
                className="object-contain"
              />
            </div>
          </li>
          <li className="glide__slide flex items-center justify-center">
            <div className="w-40">
              <img
                src={Probono}
                alt="logo Instituto Probono"
                className="object-contain"
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="glide">
        <div
          className="glide__bullets flex justify-center pb-5"
          data-glide-el="controls[nav]"
        >
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=0"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=1"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=2"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=3"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=4"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=5"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=6"
          ></button>
          <button
            className="glide__bullet p-1 bg-white/60 m-1 rounded-full hover:bg-white cursor-pointer"
            data-glide-dir="=7"
          ></button>
        </div>
      </div>
    </div>
  );
};

export default CarouselMobile;
